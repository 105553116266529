<template>
	<section class="section-wrapper">
		<base-container :fluid="value.fluid">
			<cms-text :value="`${prefix}.section_title`" :props="{variant: 'section-header', tag: value.header_tag }"/>
			<base-row :reverse="value.reverse">
				<base-col col="12" xl="6" :offset-xxl="value.reverse ? '1' : '0'">
					<image-wrapper class="img-wrapper" wider>
						<cms-image :value="`${prefix}.image`"
							lazy
							:base="{width: 318}"
							:md="{width: 690, height: 200}"
							:lg="{width: 930, height: 300}"
							:xl="{width: 690}"
						/>
					</image-wrapper>
				</base-col>
				<base-col col="12" :xl="value.reverse ? '6' : '5' " xxl="5" :offset-xl="value.reverse ? '0' : '1'">
					<cms-multi-section class="section-elements" :value="`${prefix}.section_content`"/>
				</base-col>
			</base-row>
		</base-container>
	</section>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'
export default {
	components: {
		CmsMultiSection,
		ImageWrapper
	},
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>
:deep(.img-wrapper) {
	min-height: 200px;
	@include media-breakpoint-up(lg) {
		min-height: 300px;
	}
	@include media-breakpoint-up(xxl) {
		min-height: 476px;
	}
}
.section-elements {
	& :deep(:first-child) {
		margin-top: 0;
	}
}
</style>
